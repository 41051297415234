import { globalHistory } from "@reach/router";
import nprogress from "nprogress";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

nprogress.configure({
  showSpinner: false,
  minimum: 0.3,
});

const enableHeap = Boolean(process.env.GATSBY_HEAP_APP_ID);

const initHeap = () => {
  (window.heap = window.heap || []),
    (window.heap.load = function(e, t) {
      (window.heap.appid = e), (window.heap.config = t = t || {});
      var a = document.createElement("script");
      (a.type = "text/javascript"),
        (a.async = !0),
        (a.src = process.env.GATSBY_APPSPECTOR_API_ENDPOINT + "/proxy/h/get/js/heap-" + e + ".js");
      var n = document.getElementsByTagName("script")[0];
      n.parentNode.insertBefore(a, n);
      for (
        var o = function(e) {
            return function() {
              window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            "addEventProperties",
            "addUserProperties",
            "clearEventProperties",
            "identify",
            "resetIdentity",
            "removeEventProperty",
            "setEventProperties",
            "track",
            "unsetEventProperty",
          ],
          c = 0;
        c < p.length;
        c++
      )
        window.heap[p[c]] = o(p[c]);
    });
  window.heap.load(process.env.GATSBY_HEAP_APP_ID);
};

const initCrisp = () => {
  window.$crisp = [["safe", true]];
  window.CRISP_WEBSITE_ID = "14e20597-e85f-43b3-9498-222f0fc8f62c";
  (function() {
    const d = document;
    const s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = true;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
};

export const onClientEntry = () => {
  if (enableHeap) {
    initHeap();
  }
};

export const onInitialClientRender = () => {
  window.addEventListener("load", () => {
    window.addEventListener(
      "scroll",
      () => {
        initCrisp();
      },
      { once: true },
    );
  });

  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete();
};

export const onRouteUpdate = () => {
  nprogress.done();
};
